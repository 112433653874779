import React from 'react';

import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';

import { useStaticQuery, graphql, Link } from 'gatsby';
import { useIntl } from 'react-intl';

import { localizedPath } from '../../utils';

import LocaleLink from '../LocaleLink';

import KedooImg from '../../images/logo.png';

const NavbarLink = (props) => <Nav.Link as={LocaleLink} activeClassName="active" {...props} />;

const Header = ({ siteTitle, location }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          copyrightTitle
          pageLinks {
            href
            title
          }
          i18nLocales {
            locales
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();

  return (
    <Navbar bg="white" expand="lg" sticky="top" className="text-uppercase">
      <Container>
        <Navbar.Brand to="/" as={LocaleLink}>
          <Image fluid src={KedooImg} alt={site.siteMetadata.title} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="header-navbar" />
        <Navbar.Collapse id="header-navbar" className="justify-content-end">
          <Nav className="mr-md-2 pt-2 pt-lg-0">
            {site.siteMetadata.pageLinks.map(({ href, title }) => (
              <NavbarLink key={href} to={href}>
                {formatMessage({ id: title })}
              </NavbarLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
